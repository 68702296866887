// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
@font-face {
  font-family: "Marguerite";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/MargueriteGrotesk2022-Regular.woff");
}

@font-face {
  font-family: "Marguerite";
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/MargueriteGrotesk2022-Light.woff");
}

@font-face {
  font-family: "Marguerite";
  font-style: oblique;
  font-weight: 700;
  src: url("/fonts/MargueriteGrotesk2022-Bold.woff");
}

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// borders: #e08e0b
.bg-nav {
  background-color: #f39c12 !important;
}
@import '~vue-search-select/dist/VueSearchSelect.css';